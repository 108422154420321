@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

body {
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Inter", sans-serif;
}

.step-form-1 {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.imgcolver {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  object-position: center center;
}

.highlighted {
  font-weight: 700;
  color: #047fe0;
  font-size: 13px;
  line-height: 143%;
}
svg {
  max-width: 100%;
}

.MuiTooltip-popper .MuiTooltip-tooltip {
  background-color: #444444;
}
.MuiTooltip-arrow:before {
  background-color: #444444 !important;
}
.rmdp-container {
  width: 100%;
}

/* Date Picker Css Start */

.rmdp-container {
  width: 100%;
  flex: 1;
  min-width: 150px;
}
.rmdp-container input {
  font-size: 16px !important;
}
.rmdp-header-values span {
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
}
.rmdp-header .rmdp-arrow {
  border-color: rgba(0, 0, 0, 0.56);
  margin: 0;
  max-width: 3px;
  flex: 0 0 3px;
}
.rmdp-header .rmdp-arrow-container {
  align-items: center;
  height: 24px;
  width: 24px;
  border-radius: 3px;
}
.rmdp-header .rmdp-arrow-container:hover {
  background: #e9e9e9;
  box-shadow: none !important;
}
.rmdp-arrow-container:hover .rmdp-arrow {
  border-color: rgba(0, 0, 0, 0.56) !important;
}
.rmdp-left .rmdp-arrow {
  margin-left: 2px;
}
.rmdp-right .rmdp-arrow {
  margin-right: 2px;
}
.rmdp-week-day {
  color: rgba(0, 0, 0, 0.38) !important;
  font-size: 12px !important;
  line-height: 20px;
  letter-spacing: 0.4px;
  font-weight: 400 !important;
}
.rmdp-day-picker .rmdp-day span {
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.17px;
  font-weight: 400;
  inset: 1px;
}

.rmdp-day:not(.rmdp-disabled, .rmdp-day-hidden) span:hover {
  background: rgba(63, 92, 118, 0.06) !important;
  color: #047fe0 !important;
}
.rmdp-selected span,
.rmdp-selected:not(.rmdp-disabled, .rmdp-day-hidden) span:hover {
  background: #047fe0 !important;
  box-shadow: none !important;
  color: #ffffff !important;
}
.rmdp-selected span {
  background: #047fe0 !important;
  box-shadow: none !important;
}
.rmdp-day:not(.rmdp-disabled, .rmdp-day-hidden) span:hover {
  background: #047fe0 !important;
  color: #ffffff !important;
}
.rmdp-day.rmdp-today span,
.rmdp-day.rmdp-today span:hover {
  background: rgba(4, 127, 224, 0.1) !important;
  color: #047fe0 !important;
}

.rmdp-panel .rmdp-panel-header {
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rmdp-panel .rmdp-panel-body {
}

.rmdp-panel .rmdp-panel-body li {
  background-color: #3f5c76;
  border-radius: 20px;
  box-shadow: none !important;
}
.rmdp-panel .rmdp-panel-body li .b-date {
  font-size: 13px;
  font-weight: 400;
  padding: 5px 4px 4px;
}
.rmdp-panel .rmdp-panel-body li .b-deselect {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.5);
  transition: all 0.25s ease-in-out;
  margin: 5px;
  transform: rotate(45deg);
  display: flex;
  align-items: center;
  justify-content: center;
}
.rmdp-panel .rmdp-panel-body li .b-deselect:hover {
  background-color: rgba(255, 255, 255, 1);
}
.rmdp-panel .rmdp-panel-body li .b-deselect span {
  color: #3f5c76;
  font-size: 15px;
  line-height: 100%;
  font-weight: 600;
  transform: rotate(0deg);
}
.rmdp-toolbar.bottom {
  justify-content: flex-end;
  padding: 10px 15px;
}
.rmdp-toolbar.bottom div {
  color: #047fe0;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  text-transform: uppercase;
  padding: 6px 8px !important;
  background-color: transparent;
  flex-basis: inherit;
  margin: 0px;
}
.rmdp-toolbar.bottom div:hover {
  box-shadow: none;
  background-color: rgba(4, 127, 224, 0.1);
}
.rmdp-toolbar.bottom div:first-child {
  color: #3f5c76;
}
.rmdp-toolbar.bottom div:first-child:hover {
  background-color: rgba(63, 92, 118, 0.1);
}

/* Time-Picker Css Start */

.rmdp-time-picker {
  padding: 12px 5px !important;
  min-width: 170px !important;
}
.rmdp-time-picker .rmdp-arrow-container {
}

.rmdp-time-picker .rmdp-arrow-container {
  align-items: center;
  height: 24px;
  width: 24px;
  border-radius: 3px;
}
.rmdp-time-picker .rmdp-arrow-container:hover {
  background: #e9e9e9;
  box-shadow: none !important;
}
.rmdp-time-picker .rmdp-arrow-container:hover .rmdp-arrow {
  border-color: rgba(0, 0, 0, 0.56) !important;
}
.rmdp-time-picker .rmdp-arrow {
  border-color: rgba(0, 0, 0, 0.56);
}
.rmdp-time-picker .rmdp-up .rmdp-arrow {
  margin-top: 2px;
}
.rmdp-time-picker .rmdp-down .rmdp-arrow {
  margin-bottom: 5px;
}

.rmdp-time-picker .rmdp-up {
  margin-bottom: 8px;
}

.rmdp-time-picker .rmdp-down {
  margin-top: 8px;
}

.rmdp-time-picker div input {
  border: none !important;
  width: 30px !important;
  border-radius: 3px;
  background: #405c75;
  color: #fff;
  box-shadow: none !important;
  outline: none;
}
.rmdp-shadow {
  box-shadow: 0px 4px 18px 3px rgba(0, 0, 0, 0.12),
    0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 6px 6px -3px rgba(0, 0, 0, 0.2) !important;
}

.rmdp-ep-shadow:after {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.12) !important;
}

/* -------------------------- */

.error-arrow-top {
  border-bottom: 3px solid #e67063;
  width: 6px;
  height: 6px;
  display: block;
  position: absolute;
  top: -6px;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  right: 5px;
}

.reason-text-view .ql-container.ql-snow {
  border: none;
}
.reason-text-view .ql-editor {
  margin: 0px;
  min-height: inherit;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
}

.rmdp-range {
  background-color: #3f5c76 !important;
  box-shadow: none !important;
  overflow: hidden;
}
.rmdp-range:not(.rmdp-disabled, .rmdp-day-hidden) span:hover {
  background-color: #3f5c76 !important;
}
.Toastify__close-button {
  min-width: 24px;
  max-width: 24px;
  width: 24px;
}
.rmdp-range-hover.start:not(.force),
.rmdp-range.start:not(.force) {
  border-bottom-left-radius: 100px !important;
  border-top-left-radius: 100px !important;
}
.rmdp-range-hover.end:not(.force),
.rmdp-range.end:not(.force) {
  border-bottom-right-radius: 100px !important;
  border-top-right-radius: 100px !important;
}

.MuiClock-clock {
  z-index: 100 !important;
}
.Toastify__progress-bar--success {
  background-color: #047fe0 !important;
}
.Toastify__toast--success .Toastify__toast-icon svg {
  fill: #047fe0 !important;
}
.MuiTooltip-tooltip {
  max-width: 310px;
}

/* clears the ‘X’ from Internet Explorer */
input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
.only.rmdp-month-picker,
.only.rmdp-year-picker {
  width: 300px !important;
  height: auto !important;
}

.only.rmdp-month-picker .rmdp-day,
.only.rmdp-year-picker .rmdp-day {
  margin: 5px auto !important;
}
.rmdp-disabled {
  position: relative;
}
.rmdp-disabled .leave-type:hover {
  background-color: rgba(63, 92, 118, 0.06);
}

.rmdp-calendar {
  margin: 0 auto;
}

text.report-badges {
  transform: translateY(-1px);
}

.ql-editor.ql-blank::before {
  left: 0px !important;
  font-style: normal !important;
}

.text-editor .ql-editor {
  color: #000000;
  margin-bottom: 0px;
  min-height: inherit;
}

.text-editor .ql-container.ql-snow {
  border-bottom: none !important;
}
.text-editor .ql-container.ql-snow .ql-editor {
  margin-top: 0px;
  padding: 0px;
  background-color: transparent !important;
}
.date-picker-with-filter
  .rmdp-day:not(.rmdp-disabled, .rmdp-day-hidden)
  span:hover {
  color: #ffffff !important;
}

.date-picker-with-filter .rmdp-day.rmdp-today span,
.date-picker-with-filter .rmdp-day.rmdp-today span:hover {
  color: #ffffff !important;
}

.chart-range-picker.rmdp-wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 772px;
}
.filter-date {
  order: -1;
}

.chart-range-picker .rmdp-top-class {
  flex: 1;
  display: flex;
}
.chart-range-picker .rmdp-calendar {
  width: 100%;
}
.chart-range-picker .rmdp-day-picker > div {
  flex: 1;
  margin: 0px;
  padding: 0 16px 16px !important;
}

.chart-range-picker .rmdp-header .rmdp-left {
  position: absolute;
  left: 6px;
}

.chart-range-picker .rmdp-header .rmdp-right {
  position: absolute;
  right: 6px;
}

.chart-range-picker .rmdp-header > div {
  margin: 0px 16px;
}

.chart-range-picker .rmdp-day {
  height: 36px;
  width: 36px;
  border-radius: 4px !important;
  background: #fff !important;
}

.chart-range-picker .rmdp-week:not(:last-child) {
  margin-bottom: 2px;
}

.chart-range-picker .rmdp-day {
  color: rgba(0, 0, 0, 0.87) !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.chart-range-picker .rmdp-range {
  background-color: #e0f1ff !important;
}

.chart-range-picker .rmdp-range.start,
.chart-range-picker .rmdp-range.end {
  border-radius: 4px !important;
  background-color: #047fe0 !important;
  color: #ffffff !important;
}

.chart-range-picker .rmdp-range.start span,
.chart-range-picker .rmdp-range.end span {
  color: #ffffff !important;
}

.chart-range-picker
  .rmdp-range.end:not(.rmdp-disabled, .rmdp-day-hidden)
  span:hover,
.chart-range-picker
  .rmdp-range.start:not(.rmdp-disabled, .rmdp-day-hidden)
  span:hover {
  background-color: #047fe0 !important;
}
.worklog-editor .ql-editor {
  min-height: inherit;
}
.text-desc-box .ql-toolbar {
  margin-bottom: 8px;
  margin-top: 18px;
}
.text-desc-box .ql-toolbar.ql-snow {
  margin-top: 0px;
}

.text-desc-box .ql-container.ql-snow {
  border-bottom: 1px solid rgba(81, 95, 124, 0.25) !important;
  min-height: 56px;
  padding-bottom: 8px;
}

.text-desc-box .ql-editor {
  padding: 8px;
  border-radius: 3px;
  cursor: pointer;
  margin-top: 6px;
}

.text-desc-box .ql-editor ul,
.text-desc-box .ql-editor ol {
  padding-left: 10px;
}

.text-desc-box .ql-editor:hover {
  background-color: #f4f4f4;
}

.text-desc-box .ql-editor:hover ~ .see-more-desc-btn {
  background: linear-gradient(90deg, transparent, #f4f4f4 25%);
}

.text-desc-box div {
  position: relative;
}

.task-comment-box .public-DraftStyleDefault-block span {
  color: #000000;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: "0.17px";
}

.task-comment-box
  .public-DraftStyleDefault-block
  span[data-testid="mentionText"]
  span
  span {
  display: inline-flex;
  position: relative;
  background-color: #e7eaec !important;
  color: #3f5c76 !important;
  padding: 2px 4px !important;
  border-radius: 50px !important;
  z-index: -100 !important;
  position: relative;
  font-size: 14px !important;
  line-height: 18px;
  font-weight: 500;
  text-decoration: none;
}

.task-comment-box
  .public-DraftStyleDefault-block
  span[data-testid="mentionText"] {
  padding: 0 !important;
  background: transparent !important;
}

body:has(.mention-user-name)
  .DraftEditor-editorContainer
  .public-DraftStyleDefault-block
  > span:last-child
  span[data-text="true"] {
  color: #047fe0;
  font-weight: 400;
  font-size: 14px;
}

.task-comment-box .public-DraftStyleDefault-block {
  margin: 0px;
}

.task-comment-box .public-DraftEditor-content {
  margin: 0px;
  padding: 8px 0px;
  border-bottom: 1px solid rgba(81, 95, 124, 0.25);
  min-height: 64px;
}

.public-DraftEditorPlaceholder-root {
  top: 8px;
}

.public-DraftEditorPlaceholder-inner {
  color: #000000;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: "0.17px";
}

.text-desc-box .show-desc-box:hover ~ .see-more-desc-btn {
  background: linear-gradient(90deg, transparent, #ffffff 25%);
}
.chart-tooltip-box {
  padding: 12px;
  background: #444444;
  border: none;
  width: 390px;
  border-radius: 4px;
}

.project-title {
  display: flex;
  margin-bottom: 14px;
  max-width: 100%;
  white-space: pre-wrap !important;
}
.project-title span {
  display: block;
  white-space: pre-wrap !important;
}

.apexcharts-tooltip {
  transform: translateY(-50%);
}
.apexchartsstacked-bar-chart-task .apexcharts-legend .apexcharts-legend-series,
.apexchartsstacked-bar-chart-members
  .apexcharts-legend
  .apexcharts-legend-series,
.teams-report-pie-wrapper .apexcharts-legend .apexcharts-legend-series {
  gap: 8px;
}

.apexchartsstacked-bar-chart-task
  .apexcharts-legend
  .apexcharts-legend-series
  .apexcharts-legend-marker,
.apexchartsstacked-bar-chart-members
  .apexcharts-legend
  .apexcharts-legend-series
  .apexcharts-legend-marker,
.teams-report-pie-wrapper
  .apexcharts-legend
  .apexcharts-legend-series
  .apexcharts-legend-marker {
  width: 12px !important;
  height: 12px !important;
}
.apexchartsstacked-bar-chart-task
  .apexcharts-legend
  .apexcharts-legend-series
  .apexcharts-legend-marker
  svg,
.apexchartsstacked-bar-chart-members
  .apexcharts-legend
  .apexcharts-legend-series
  .apexcharts-legend-marker
  svg,
.teams-report-pie-wrapper
  .apexcharts-legend
  .apexcharts-legend-series
  .apexcharts-legend-marker
  svg {
  border-radius: 2px !important;
}

.report-date-range-picker .chart-range-picker .rmdp-range.start,
.report-date-range-picker .chart-range-picker .rmdp-range.end {
  background-color: rgb(4 127 224 / 80%) !important;
}

.report-date-range-picker .rmdp-day.rmdp-today {
  background-color: rgb(4 127 224) !important;
}

.report-date-range-picker .rmdp-day.rmdp-today span {
  color: #ffffff !important;
}

.report-date-range-picker .rmdp-day.rmdp-today.rmdp-range.end,
.report-date-range-picker .rmdp-day.rmdp-today.rmdp-range.start,
.report-date-range-picker .rmdp-day.rmdp-today.rmdp-range.start.end {
  background-color: rgb(4 127 224) !important;
}
