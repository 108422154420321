.demo-wrapper .rdw-editor-main {
  overflow: visible !important;
}
.rdw-editor-main {
  font-size: 14px !important;
}

.demo-wrapper .rdw-suggestion-dropdown {
  min-width: 200px;
  bottom: 15px !important;
}

.demo-wrapper .rdw-suggestion-option {
  cursor: pointer !important;
}

.demo-wrapper .rdw-suggestion-option:hover {
  background-color: #e7eaec !important;
}

.rdw-mention-link {
  background-color: #e7eaec !important;
  color: #3f5c76 !important;
  padding: 2px 4px !important;
  border-radius: 50px !important;
  z-index: -100 !important;
  position: relative;
  font-size: 14px !important;
}

.rdw-editor-toolbar {
  border: none !important;
  background-color: #515f7c14 !important;
  display: inline-flex !important;
  padding: 0px !important;
  border-radius: 6px !important;
}
.rdw-inline-wrapper,
.rdw-list-wrapper,
.rdw-text-align-wrapper {
  margin: 0px !important;
}

.rdw-option-wrapper {
  border: none !important;
  background: transparent !important;
  width: 32px !important;
  height: 32px !important;
  padding: 0px !important;
  border-radius: 0px !important;
}

.rdw-inline-wrapper .rdw-option-wrapper:first-child {
  border-radius: 6px 0px 0px 6px !important;
}

.rdw-text-align-wrapper .rdw-option-wrapper {
  border-radius: 0px !important;
}

.rdw-list-wrapper .rdw-option-wrapper:last-child {
  border-radius: 0px 6px 6px 0px !important;
}

.rdw-option-wrapper:hover {
  background-color: #e4e4e4 !important;
  box-shadow: none !important;
}

.rdw-option-active {
  background-color: #e4e4e4 !important;
  box-shadow: none !important;
}

.rdw-suggestion-wrapper > span > span > span {
  color: #047fe0 !important;
}

/* wysiwyg-mention */
.wysiwyg-mention {
  display: inline-flex;
  position: relative;
  background-color: #e7eaec !important;
  color: #3f5c76 !important;
  padding: 2px 4px !important;
  border-radius: 50px !important;
  z-index: -100 !important;
  position: relative;
  font-size: 14px !important;
  text-decoration: none;
}

.mention-link {
  display: inline-flex;
  position: relative;
  background-color: #e7eaec !important;
  color: #3f5c76 !important;
  padding: 2px 4px !important;
  border-radius: 50px !important;
  z-index: -100 !important;
  position: relative;
  font-size: 14px !important;
  line-height: 18px;
  font-weight: 500;
  text-decoration: none;
}

.toolbar {
  border: none !important;
  background-color: #515f7c14 !important;
  display: inline-flex !important;
  padding: 0px !important;
  border-radius: 6px !important;
}

.toolbar-button-wrapper {
  margin: 0px;
}

.toolbar-button {
  background: none;
  border: none;
  padding: 4px 8px;
  cursor: pointer;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s;
}

.toolbar-button svg {
  width: 24px;
  height: 24px;
  fill: #666;
}

.toolbar-button-active {
  background-color: #e0e0e0;
  border-radius: 0px;
}

.toolbar .toolbar-button-wrapper:first-child .toolbar-button:hover,
.toolbar .toolbar-button-wrapper:first-child .toolbar-button-active {
  border-radius: 6px 0px 0px 6px !important;
}

.toolbar .toolbar-button-wrapper:last-child .toolbar-button:hover,
.toolbar .toolbar-button-wrapper:last-child .toolbar-button-active {
  border-radius: 0px 6px 6px 0px !important;
}

.toolbar-button:hover {
  background-color: #e0e0e0;
  border-radius: 0px !important;
}

.toolbar-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px #007bff;
}
