.mention {
  display: inline-flex;
  position: relative;
  background-color: #e7eaec !important;
  color: #3f5c76 !important;
  padding: 2px 4px !important;
  border-radius: 50px !important;
  z-index: -100 !important;
  position: relative;
  font-size: 14px !important;
  line-height: 18px;
  text-decoration: none;
  font-weight: 500 !important;
  letter-spacing: 0.15px;
  margin-bottom: 2px;
}

.mentionSuggestions {
  background: #fff;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  transform-origin: 50% 0%;
  transform: scaleY(0);
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.17);
}

.mentionSuggestionsPopup {
  width: 262px;
  z-index: 100;
}
.mentionSuggestionsPopupVisible {
}
.mentionSuggestionsEntryContainer {
  display: table;
  width: 100%;
}

.mentionSuggestionsEntryContainerLeft,
.mentionSuggestionsEntryContainerRight {
  display: table-cell;
  vertical-align: middle;
}

.mentionSuggestionsEntryContainerRight {
  width: 100%;
  padding-left: 8px;
}

.mentionSuggestionsEntry {
  padding: 7px 10px;
  transition: background-color 0.4s cubic-bezier(0.27, 1.27, 0.48, 0.56);
}
.mentionSuggestionsEntry:active {
  background-color: #cce7ff;
}

.mentionSuggestionsEntryFocused {
  composes: mentionSuggestionsEntry;
  background-color: #e6f3ff;
}

.mentionSuggestionsEntryText,
.mentionSuggestionsEntryTitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mentionSuggestionsEntryText {
}

.mentionSuggestionsEntryTitle {
  font-size: 80%;
  color: #a7a7a7;
}

.mentionSuggestionsEntryAvatar {
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
